<template>
  <div>
    <div v-if="!node">
      <span>{{ $t("workflowDesigner.selectWidget") }}</span>
    </div>
    <div v-else>
      <FormHelper
        v-model="values"
        :form="form"
        :config="formConfig"
        @change="onChange"
        @action="onAction"
      />
    </div>
  </div>
</template>

<script>
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { addAreaPrefix } from "@/components/Workflows/Designer/Canvas/Configuration/functions";
import { bus } from "@/main";
import { SET_CUSTOM_VARIABLES } from "@/core/services/store/variables_v1.module";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  components: { FormHelper },
  props: [
    "node",
    "configValues",
    "outputValues",
    "debugValues",
    "errorValues",
    "parameters"
  ],
  data() {
    return {
      areas: ["authentication", "configuration", "input", "output", "error"],
      formConfig: {
        snippetPrefix: this.snippetPrefix(),
        labelStacked: true,
        enableVariables: true,
        customVariables: ["outputValues", "errorValues", "parameters"],
        distinctVariables: true,
        enableTypecast: true
      },
      debouncedUpdate: _.debounce(this.updateAssignmentVariables, 1000)
    };
  },
  computed: {
    values: {
      get() {
        let values = {};
        this.areas.forEach(area => (values[area] = {}));
        Object.keys(this.node.attrs.data).forEach(area => {
          if (!this.areas.includes(area)) {
            return;
          }
          this.node.attrs.data[area].forEach(field => {
            values[area][field.name] = field.value;
          });
        });
        return values;
      },
      set(values) {
        let config = _.cloneDeep(this.node.attrs.data.input);
        Object.keys(values.input).forEach(key => {
          let matchingFields = config.filter(f => f.name === key);
          matchingFields.forEach(field => {
            field.value = values.input[key];
          });
        });
        this.node.attrs.data.input = config;
      }
    },
    form: function () {
      let form = this.node?.attrs.data.input ?? [];
      return addAreaPrefix(form, "input");
    }
  },
  methods: {
    ...mapActions("variables", [SET_CUSTOM_VARIABLES]),
    snippetPrefix() {
      return (
        "workflowElements." +
        this.$store.getters.getElementByName(
          this.node.attrs.data.flow_element_name
        ).name
      );
    },
    onChange(payload) {
      this.$emit("change");
      if (
        this.node.attrs.data.flow_element_name === "assignment" &&
        payload.name === "input.variables"
      ) {
        this.debouncedUpdate(payload);
      }
      this.checkOnChangeAction(payload);
    },
    checkOnChangeAction(payload) {
      let field = this.node.attrs.data.input.find(
        f => "input." + f.name === payload.name
      );
      if (!field.onChange) {
        return;
      }
      this.$nextTick().then(() => {
        bus.$emit(
          "fireAction",
          {
            name: field.onChange,
            label: field.onChange
          },
          false
        );
      });
    },
    updateAssignmentVariables() {
      let field = this.node.attrs.data.input.find(f => f.name === "variables");
      const payload = {
        id: this.node.attrs.data.hash,
        field,
        prefix: "output"
      };
      bus.$emit("update-custom-variable-value", payload);
    },
    onAction(actionName) {
      let field = this.form.find(field => field.action === actionName);
      bus.$emit("fireAction", field, false);
    }
  }
};
</script>
