<template>
  <div>
    <!------------ START: FieldWrapper ------------>
    <component
      :is="fieldWrapper ? 'FieldWrapper' : 'div'"
      ref="fieldWrapper"
      :field="field"
      :input-group="true"
      :class="{
        'input-group': !fieldWrapper
      }"
    >
      <!------------ START: Form field input ------------>
      <div
        v-if="enableTypecast"
        ref="input"
        class="form-control"
        :contenteditable="!isDisabled"
        :data-placeholder="getSnippet(field.placeholder)"
        :class="[validationClass, showPlaceholder ? 'empty' : '']"
        v-bind="{ disabled: isDisabled }"
        @input="onContentEditableInput"
        @focus="showVariables = true"
        @keyup.esc="showVariables = !showVariables"
        @keydown.tab="showVariables = false"
      />
      <!------------ END: Form field input ------------>
      <!------------ START: Return type ------------>
      <div v-if="enableTypecast" class="input-group-append">
        <span
          v-b-popover.hover.html.top="returnTypeText"
          class="input-group-text border-left-0 bg-white px-2"
          :class="{ 'cursor-pointer': isText && typeTogglePossible }"
          @click="toggleType"
        >
          <i :class="[returnTypeIcon]" />
          <i
            v-if="isText && typeTogglePossible"
            class="fal fa-rotate toggle-empty"
          />
        </span>
      </div>
      <!------------ END: Return type ------------>
      <!------------ START: HTML input ------------>
      <input
        v-if="!enableTypecast"
        ref="input"
        v-model="computedValue"
        :type="type"
        class="form-control"
        :placeholder="getSnippet(field.placeholder)"
        :class="[validationClassAdvanced, showPlaceholder ? 'empty' : '']"
        :disabled="isDisabled"
        @focus="showVariables = true"
        @keyup.esc="showVariables = !showVariables"
        @keydown.tab="showVariables = false"
      />
      <!------------ END: HTML input ------------>
      <!------------ START: Hints ------------>
      <template v-if="fieldWrapper && field.hint" #hints>
        {{ getSnippet(field.hint) }}
      </template>
      <!------------ END: Hints ------------>
    </component>
    <!------------ START: Error messages tooltip ------------>
    <ErrorsTooltip
      v-if="isMounted"
      :target="$refs.input"
      :errors="validationErrorsAdvanced"
    />
    <!------------ END: Error messages tooltip ------------>
    <!------------ START: Variables dropdown ------------>
    <VariablesDropdown
      v-if="enableVariables && isMounted"
      v-model="showVariables"
      :filter="filter"
      :el="
        fieldWrapper
          ? $refs['fieldWrapper'].$refs['inputGroup']
          : $refs['fieldWrapper']
      "
      :input-el="$refs['input']"
      @select="setVariable"
    />
    <!------------ END: Variables dropdown ------------>
  </div>
</template>

<script>
import {
  transformValue,
  checkValueType,
  typeOf
} from "@/components/Tools/FormHelper/Helper/functions";
import { customRegex } from "@/components/Tools/FormHelper/Helper/constants";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import { base, input } from "@/components/Tools/FormHelper/Helper/mixins";
import VariablesDropdown from "@/components/Tools/FormHelper/Components/VariablesDropdown/VariablesDropdown";
import ErrorsTooltip from "@/components/Tools/FormHelper/Components/ErrorsTooltip";
import Input from "@/components/Workflows/Designer/Canvas/Configuration/Input.vue";
import { isJson } from "@/components/Tools/helperFunctions";

export default {
  name: "InputText",
  components: {
    Input,
    ErrorsTooltip,
    VariablesDropdown,
    FieldWrapper
  },
  mixins: [base, input],
  props: {
    type: {
      type: String,
      default: "text"
    }
  },
  data() {
    return {};
  },
  computed: {
    isText: function () {
      return this.returnType === "text";
    },
    isNumber: function () {
      return this.returnType === "number";
    },
    computedValue: {
      get: function () {
        let value = this.$v.value.$model;

        // Always get value as string to be editable
        return String(value);
      },
      set: function (value) {
        // Check if value became or was variable
        this.checkIsVariable(value);
        // Check if value is json and set it accordingly
        if (isJson(value) && this.returnType === "text") {
          value = JSON.parse(value);
        }
        // Set value cast or just as string
        this.lastValueSet = value;
        this.$v.value.$model = value;
      }
    },
    highlightType: function () {
      // Highlight as set with strict flag
      return this.isNumber
        ? "number"
        : this.field.strict === true
        ? "default"
        : "all";
    },
    typeTogglePossible: function () {
      let current = this.returnType,
        target = "";
      if (current !== "text" && current !== "variable") {
        // If current type is not text and not variable,
        // transformation to text is always possible
        target = "text";
      } else if (["true", "false", "null"].includes(this.value)) {
        // Value can be transformed to boolean/null
        target = this.value;
      } else if (!isNaN(this.value) && this.value !== "") {
        // Value can be transformed into number
        target = "number";
      }
      // Return target
      return target;
    },
    invalidValue: function () {
      if (!this.isNumber) return false;
      let value = this.$v.value.$model;
      return (
        value !== null &&
        isNaN(value) &&
        (!this.enableVariables || !customRegex.variable.test(value))
      );
    },
    validationClassAdvanced: function () {
      let validationClass = this.validationClass;
      // Check if value is not of type number or variable (if enabled)
      if (this.invalidValue) {
        validationClass = "is-invalid";
      }
      return validationClass;
    },
    validationErrorsAdvanced: function () {
      let errors = this.validationErrors;
      // Check if value is not of type number or variable (if enabled)
      if (this.invalidValue) {
        let snippet = this.enableVariables
          ? "invalidNumberOrVariableValue"
          : "invalidNumberValue";
        errors.unshift(this.$t("formHelper.errors." + snippet));
      }
      return errors;
    }
  },
  watch: {
    // Watch for v-model value and update contenteditable if changed from outside
    value: function (val) {
      if (val !== this.lastValueSet) {
        this.lastValueSet = val;
        this.highlightInput(val);
      }
    }
  },
  mounted() {
    this.init();
    this.isMounted = true;
  },
  methods: {
    init() {
      this.setReturnType(typeOf(this.value, true));
      this.highlightInput(this.value);
    },
    // Overwrites default checkValue method from input mixin
    checkValue(value) {
      // Check if return type and value are matching
      value = this.checkReturnType(value);
      return value;
    },
    checkReturnType(value) {
      let valueTransformed =
        this.field.strict === true
          ? String(value)
          : transformValue(value, this.returnType);
      let valid = checkValueType(valueTransformed, this.returnType);
      // If return type and value don't match, fallback to text
      if (!valid) {
        this.setReturnType("text");
        value = String(value);
      } else {
        value = valueTransformed;
      }
      return value;
    },
    checkIsVariable(value) {
      let current = this.returnType;
      // Return if return type ist not text or variable
      if (!["text", "variable"].includes(current)) {
        return;
      }
      if (current === "variable" && !customRegex.variable.test(value)) {
        // If return type is variable, but value is no variable, change to text
        this.setReturnType("text");
      } else if (current !== "variable" && customRegex.variable.test(value)) {
        // If value is variable, change return type to variable
        this.setReturnType("variable");
      }
    },
    toggleType() {
      let targetType = this.typeTogglePossible;
      if (!targetType) {
        return;
      }
      this.setReturnType(targetType);
      let value = transformValue(this.value, targetType);
      this.$v.value.$model = value;
      this.highlightInput(value);
    }
  }
};
</script>
